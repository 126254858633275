function ComponentCookieConsent(selector) {
  if (document.querySelector('.part_form_cookieconsent') !== null || selector === null) {
    return
  }

  if (localStorage.getItem('lib-cookieconsent') === null || parseInt(localStorage.getItem('lib-cookieconsent-expire')) < Date.now()) {
    setTimeout(() => {
      selector._addDataValue('state', 'active')
      selector.classList.add('is-animate')
    }, 1500)
  }

  selector.querySelector('[data-lib-cookieconsent-decline]').addEventListener('click', () => {
    ComponentCookieConsent.hide([])
  })

  selector.querySelector('[data-lib-cookieconsent-approve]').addEventListener('click', () => {
    ComponentCookieConsent.hide(['performance', 'marketing'])
  })
}

ComponentCookieConsent.hide = function(type) {
  const element = document.querySelector('.part_cookieconsent');
  LibCookieConsent.set(type)
  element.classList.remove('is-animate')

  setTimeout(() => {
    element._removeDataValue('state', 'active')
    element.remove()
  }, 500)
}

function ComponentCookieConsentForm(selector) {
  const type = localStorage.getItem('lib-cookieconsent')

  if (selector === null) {
    return
  }

  if (type !== null) {
    selector.querySelectorAll('input:not([disabled])').forEach(input => {
      input.checked = false
    })

    JSON.parse(type).forEach(type => {
      if (selector.querySelector(`input[value="${type}"]`) !== null) {
        selector.querySelector(`input[value="${type}"]`).checked = true
      }
    })
  }

  selector.addEventListener('submit', e => {
    e.preventDefault()

    const type = []

    selector.querySelectorAll('input:not([disabled])').forEach(input => {
      input.checked && type.push(input.value)
    })

    LibCookieConsent.set(type)
    location.reload()
  })
}

ComponentCookieConsent(document.querySelector('.part_cookieconsent'));
ComponentCookieConsentForm(document.querySelector('.part_form_cookieconsent'));

window.addEventListener('pageshow',function (event) {
  if (event.persisted) {
    window.location.reload();
  }
});
