(function ($) {
    let $fn = $(".comp_events");

    if ($fn.length) {
        $(window).on("scroll", function () {
            let $elm = $(".comp_events").find("[data-next-page]");

            if ($elm.length && $elm.isInViewport()) {
                if (!$elm.hasClass("mod--clicked")) {
                    $elm.trigger("click");
                    $elm.addClass("mod--clicked");
                }
            }
        });

        $(document).on('click', "[data-next-page]", function () {
            let $elm = $(this);

            $.ajax({
                data: {selectedRegion: $('[name="selectedRegion"]').val(), page: $elm.data('next-page')},
                success: function (payload) {
                    fn_ajaxHandler(payload);
                }
            });
        });
    }

    $.fn.isInViewport = function () {
        var elementTop = $(this).offset().top;
        var elementBottom = elementTop + $(this).outerHeight();
        var viewportTop = $(window).scrollTop();
        var viewportBottom = viewportTop + $(window).height();
        return elementBottom > viewportTop && elementTop < viewportBottom;
    };

})(jQuery);