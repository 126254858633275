(function ($) {
    let $fn = $(".comp_financing");

    if ($fn.length) {

        let $map = $fn.find(".elm_map svg"),
            $regions = $map.find("[data-region]"),
            $nav_items = $map.find("[data-nav-item]"),
            $area_items = $fn.find(".elm_content [data-area-item]");

        $nav_items.on("click",function () {
            let $this = $(this);
            $fn.find("[data-select]").val($this.data("nav-item")).trigger("change");
        });

        $fn.on("change","[data-select]",function () {
            let $this = $(this),
                val = $this.val(),
                $map_item = $nav_items.filter("[data-nav-item=\""+ val +"\"]");

            $nav_items.filter(".mod--active").removeClass("mod--active");
            $regions.filter(".mod--active").removeClass("mod--active");
            $map_item.addClass("mod--active");
            $map_item.parents("[data-region]").addClass("mod--active");

            if($this.filter("[data-ajax]")){
                $.ajax({
                    method: 'post',
                    data: $('#places'),
                    cache: false,
                    dataType: "json",
                    success: function (payload) {
                        fn_ajaxHandler(payload);
                    }
                });
            }
        })
    }
})(jQuery);