(function ($) {
    let $fn = $(".comp_product_category");

    if ($fn.length) {
        let $filter = $fn.find(".wrp_comp_filter"),
            $nav_items = $fn.find(".elm_nav a"),
            $nav_select = $fn.find("[data-nav-select]");

        $nav_select.on("change",function () {
            $nav_items.eq($(this).prop("selectedIndex")).click();
        });

        $fn.on("click","[data-filter]",()=> {
            $filter.stop().slideToggle();
        });

        let $range = $fn.find("[data-range]");
        if($range.length){
            $.getScript(cdnjs.nouislider).done(()=> {
                $range.rangeSlider();
            });
        }
        
        function doFilter() {
            var formData = $('#category-filter').serialize();

            $.ajax({
                method: 'post',
                data: formData,
                cache: false,
                dataType: "json",
                success: function (payload) {
                    fn_ajaxHandler(payload,function() {
                        if($('[data-range]').length) {
                            $(".comp_product_category [data-range]").rangeSlider();
                        }

                        var h_one_text = payload['payload']['h-one-text'];
                        $('.comp_product_category h1 span').html(h_one_text);
                    });
                }
            });
        }
        
        $(document).on("click", "[data-filter-remove]", function(){
            $range[0].noUiSlider.set([$range.data("min"),$range.data("max")]);
            $('[data-alt-min]').val(1);
            $('[data-alt-max]').val(100000);
            $('input[data-cat-filter]').prop('checked', false);
            doFilter();
        });
        
        $(document).on('change', '[data-cat-filter]', function(){
            doFilter();
        });
            
        $(document).on('change', '[data-alt-min], [data-alt-max]', function(){
            doFilter();
        });
    }
})(jQuery);

