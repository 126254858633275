(function ($) {
    let $fn = $(".comp_visual");

    if ($fn.length) {
        cssLoaded(function() {
            let $carousel = $fn.find('[data-visual-slider]'),
                $caption = $fn.find('[data-visual-caption]'),
                _autoplay = $carousel.data("visual-slider");

            if($caption.children().length > 1) {
                $caption.flickity({
                    contain: true,
                    pageDots: false,
                    prevNextButtons: false
                });

                $caption.on('staticClick.flickity', function(event, pointer, cellElement, cellIndex ) {
                    slideClick($(cellElement));
                });
            }

            if($carousel.children().length > 1) {

                $carousel.on("ready.flickity",function () {
                    let $video = $carousel.find(".elm_item").first().find("video");
                    if ($video.length){
                        $video[0].play();
                    }
                });

                $carousel.flickity({
                    imagesLoaded: false,
                    percentPosition: false,
                    setGallerySize: false,
                    pageDots: true,
                    wrapAround: true,
                    contain: true,
                    sync: '[data-visual-caption]',
                    autoPlay: false,
                    prevNextButtons: false,
                    selectedAttraction: 0.025,
                    friction: 0.6
                });

                let $carousel_img = $carousel.find('.elm_item .elm_item_image:visible'),
                    docStyle = document.documentElement.style,
                    transformProp = typeof docStyle.transform === 'string' ? 'transform' : 'WebkitTransform';

                let flkty = $carousel.data('flickity');
                $carousel.find(".flickity-page-dots").append("<li class='elm_progress'><div data-visual-progress class='animated'></div></li>");

                let $progress = $fn.find("[data-visual-progress]");

                $carousel.on('scroll.flickity', function () {
                    flkty.slides.forEach(function (slide, i) {
                        let img = $carousel_img[i],
                            x = (slide.target + flkty.x) * -1 / 3;

                        if(img.length){
                            img.style[transformProp] = 'translateX(' + x + 'px)';
                        }
                    });
                    startProgressbar();
                });

                $carousel.on('staticClick.flickity', function(event, pointer, cellElement, cellIndex ) {
                    slideClick($($caption.data("flickity").cells[cellIndex].element));
                });

                let $slides = $carousel.find('.elm_item');

                $carousel.on('settle.flickity', function() {
                    $slides.each( function( i, cell ) {
                        let $video = $(cell).find('video');
                        if ( $video.length && win.width() > 960 ) {
                            if ( cell == flkty.selectedElement ) {
                                $video[0].play();
                            }
                            else {
                                $video[0].pause();
                                $video[0].currentTime = 0;
                                $video[0].load();
                            }
                        }
                    });
                });


                let time = _autoplay/1000;
                let isPause,
                    tick,
                    percentTime;
                    
                    
                var hidden, visibilityChange; 
                if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support 
                  hidden = "hidden";
                  visibilityChange = "visibilitychange";
                } else if (typeof document.msHidden !== "undefined") {
                  hidden = "msHidden";
                  visibilityChange = "msvisibilitychange";
                } else if (typeof document.webkitHidden !== "undefined") {
                  hidden = "webkitHidden";
                  visibilityChange = "webkitvisibilitychange";
                }
                 
                var videoElement = document.getElementById("videoElement");
                
                // If the page is hidden, pause the video;
                // if the page is shown, play the video
                function handleVisibilityChange() {
                  if (document[hidden]) {
                    isPause = true;
                  } else {
                    isPause = false;
                  }
                }
                
                if (typeof document.addEventListener === "undefined" || hidden === undefined) {
                    console.log("Page Visibility API not supported");
                } else {
                    document.addEventListener(visibilityChange, handleVisibilityChange, false);
                }

                $fn.on({
                    mouseenter: function() {
                        isPause = true;
                    },
                    mouseleave: function() {
                        isPause = false;
                    }
                });
                function startProgressbar() {
                    resetProgressbar();
                    percentTime = 0;
                    isPause = false;
                    tick = setInterval(interval, 10);
                }
                function interval() {
                    if(isPause === false) {
                        percentTime += 1 / (time+0.1);
                        $progress.css({
                            width: percentTime+"%"
                        });
                        if(percentTime >= 100)
                        {
                            $carousel.flickity('next', true);
                        }
                    }
                }
                function resetProgressbar() {
                    $progress.css({
                        width: 0+'%'
                    });
                    clearTimeout(tick);
                }
                startProgressbar();
            }
        });

        $fn.on("click","[data-scroll]",function () {
            $fn.next("section").anchor_anim(500);
        });
        
        function slideClick($slide) {
            if(win.width() < 768){
                let $link = $slide.find("a");
                if($link.length){
                    $link[0].click();
                }
            }
        }
    }
})(jQuery);