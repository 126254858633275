(function($) {
    let $fn = $("#layout_header");

    if ($fn.length) {
        let $nav = $("#layout_nav");

        //mobile nav

        $.getScript(cdnjs.touchswipe).done(()=> {
            $('.ssm-nav').slideAndSwipe();
        });

        if ($nav.length) {
            var $animation_delay = 60,
                $animation_duration = 600;

            $.fn.fn_animate = function($menu_id,$direction,$val) {
                var $el = $(this),
                    $animation_delay = $el.parent().find("[data-menu=\""+$menu_id+"\"]").data("menu-delay") + $animation_duration;

                var $anim_out, $anim_in;
                if ($direction === "in") {
                    $anim_out = "animate--out-bottom";
                    $anim_in = "animate--in-top"
                } else {
                    $anim_out = "animate--out-top";
                    $anim_in = "animate--in-bottom"
                }

                $el.closest(".elm_content").addClass("mod--animated");

                $el.addClass($anim_out).parent().find("[data-menu=\""+$menu_id+"\"]").addClass($anim_in);


                if ($nav.find("[data-menu-breadcrumbs]").children("li").length === 1) {
                    $nav.find("[data-submenu-back]").removeClass("mod--active");
                }

                setTimeout(function(){
                    $el.removeClass($anim_out+" mod--active").parent().find("[data-menu=\""+$menu_id+"\"]").removeClass($anim_in).addClass("mod--active");
                    $el.closest(".elm_content").removeClass("mod--animated");
                    if ($direction === "in") {
                        $nav.find("[data-menu-breadcrumbs]").children("li").removeClass("mod--active").parent().append("<li class='mod--active' data-menu-id='"+$menu_id+"'>"+$val+"</li>").find("mod--active");
                    }

                    if ($nav.find("[data-menu-breadcrumbs]").children("li").length > 1) {
                        $nav.find("[data-submenu-back]").addClass("mod--active");
                    }
                }, $animation_delay);
            };

            $nav.find("[data-menu]").each(function() {
                var $el = $(this),
                    $el_length = $el.children("li").length - 1;

                $el.children("li").each(function(i){
                    $(this).css("animation-delay", i*$animation_delay+"ms");
                });

                $el.attr("data-menu-delay", $el_length * $animation_delay);
            });


            $nav.on("click", "[data-menu-breadcrumbs] [data-menu-id]", function() {
                var $el = $(this),
                    $menu_active = $nav.find("[data-menu].mod--active"),
                    $menu_id = $el.data("menu-id");

                if (!$el.hasClass("mod--active")) {
                    $el.addClass("mod--active").nextAll().remove();
                    $menu_active.fn_animate($menu_id, "out");
                }
            });


            $nav.on("click", "[data-submenu]", function(e){
                e.preventDefault();
                var $el = $(this),
                    $val = $el.prev().find("span").text(),
                    $menu_id = $el.data("submenu");

                $el.closest("[data-menu]").fn_animate($menu_id, "in", $val);
            });

            $nav.on("click", "[data-submenu-back]", function(e){
                e.preventDefault();
                var $menu = $nav.find("[data-menu].mod--active"),
                    $menu_prev = $("[data-menu-breadcrumbs] [data-menu-id].mod--active").prev(),
                    $menu_id = $menu_prev.data("menu-id");

                $menu_prev.addClass("mod--active").nextAll().remove();
                $menu.fn_animate($menu_id, "out");
            });
        }

        //headroom
        $.getScript(cdnjs.headroom).done(()=> {
            let headroom = new Headroom($fn[0], {
                offset: 46,
                tolerance : {
                    up : 5,
                    down : 0
                },
                classes: {
                    "initial" : "headroom",
                    "pinned": "headroom--pinned",
                    "unpinned": "headroom--unpinned",
                    "top" : "headroom--top",
                    "notTop" : "headroom--not-top"
                }
            });
            headroom.init();
        });

        //dropdown
        let timeout,
            drop = $fn.find(".elm_dropdown");

        $.fn.close = function() {
            let elm = $(this);
            elm.removeClass("mod--toggled").next("[aria-hidden]").attr("aria-hidden","true");
            timeout = setTimeout(function(){
                elm.attr("aria-expanded","false");
            },300);
        };
        $.fn.open = function() {
            $(this).closest(".elm_submenu").find("[aria-expanded=\"true\"]").close();
            $(this).addClass("mod--toggled").attr("aria-expanded","true").next("[aria-hidden]").removeAttr("aria-hidden");
        };

        $fn.find(".elm_nav_item").on("click mouseenter",function () {
            let el = $(this);
            drop.not(el.closest(drop)).find('[aria-expanded="true"]').close();
        });

        drop.on("click mouseenter","[aria-expanded]",function(e){
            let el = $(this);
            e.preventDefault();
            e.stopPropagation();

            if(!$(el.parents()[3]).hasClass("elm_nav") && e.originalEvent.type == "mouseover") {
                return; //blokuje hover na submenu
            }
            if($(el.parents()[3]).hasClass("elm_nav") && e.originalEvent.type == "click") {
                // return; //blokuje hover na submenu
            }

            clearTimeout(timeout);

            html.on("click", function(ev) {
                if(!$(ev.target).parents().filter("#layout_header").length){
                    el.close();
                }
            });
            win.one("scroll",function () {
                el.close();
            });
            if (el.hasClass("mod--toggled") && e.type == "click") {
                el.close();
            } else {
                el.open();
            }
            drop.not(el.closest(drop)).find('[aria-expanded="true"]').close();
        });
    }
})(jQuery);