$.fn.rangeSlider = function () {
    let $el = $(this);
    let $min = $el.closest(".wrp_filter_category").find("[data-alt-min]"),
        $max = $el.closest(".wrp_filter_category").find("[data-alt-max]"),
        min = $el.data("min"),
        max = $el.data("max");


    noUiSlider.create($el[0], {
        range: {
            "min": min,
            "max": max
        },
        start: [$min.val() ? $min.val() : min, $max.val() ? $max.val() : max],
        margin: 10,
        step: 10,
        connect: true,
        pips: {
            mode: 'positions',
            values: [0,25,50,75,100],
            density: 4
        }
    });

    $el[0].noUiSlider.on("end",(values)=> {
        $min.val(values[0]);
        $max.val(values[1]);
        $min.trigger('change');
    })
};