function fn_ajaxHandler(payload,callback) {
    if (payload) {
        // přesměrování "this" přesměruje na aktuální stránku
        if (payload.redirect) {
            if (payload.redirect === 'this') {
                location.href = window.location.href.split("#")[0];
            } else {
                location.href = payload.redirect;
            }
        }

        // výměna snippetů v šabloně - každý snippet musí mít unikátní ID
        if (payload.snippets) {
            for (let i in payload.snippets) {

                var htmlSnippet = payload.snippets[i];
                var objectSnippet = $(htmlSnippet);
                var redrawWholeComponent = true;

                if(typeof payload.redrawBlocks !== 'undefined' && typeof payload.redrawBlocks[i] !== 'undefined' && payload.redrawBlocks[i].length) {
                    redrawWholeComponent = false;

                    for (let iBlock in payload.redrawBlocks[i]) {

                        var idToFind = payload.redrawBlocks[i][iBlock].block;
                        var insertType = payload.redrawBlocks[i][iBlock].insertType;

                        var htmlToReplace = objectSnippet.find("#" + idToFind);

                        switch(insertType) {
                            case 'default':
                                $("#" + idToFind).replaceWith(htmlToReplace);
                                break;
                            case 'append':

                                if(htmlToReplace.length) {
                                    htmlToReplace = htmlToReplace.html();
                                    $("#" + idToFind).append(htmlToReplace);
                                }
                                break;
                        }
                    }
                }

                if(redrawWholeComponent) {
                    var snippetItem = $(htmlSnippet);
                    if(snippetItem.is('#' + i)) {
                        $("#" + i).replaceWith(snippetItem);
                    } else {
                        var snippet = snippetItem.find("#" + i);
                        $("#" + i).replaceWith(snippet);
                    }
                }
            }
        }

        // výměna adresy v adresním řádku
        if (payload.replaceState) {
            window.history.replaceState(null, payload.replaceState.title || null, payload.replaceState.url);

            if (payload.replaceState.title) {
                document.title = payload.replaceState.title;
            }

            if (typeof window.ga === "function") {
                ga('send', 'pageview', window.location.pathname + window.location.search);
            }
        }

        if (callback) {
            callback();
        }
    }
}