let cdnjs = {
    "picturefill": "https://cdnjs.cloudflare.com/ajax/libs/picturefill/3.0.3/picturefill.min.js",
    "google_map": "https://maps.googleapis.com/maps/api/js?key=AIzaSyCPr6_LuWwQhz5SH9OmSuT01j5LPvq7BIo",
    "touchswipe": "https://cdnjs.cloudflare.com/ajax/libs/jquery.touchswipe/1.6.18/jquery.touchSwipe.min.js",
    "headroom": "https://cdnjs.cloudflare.com/ajax/libs/headroom/0.9.4/headroom.min.js",
    "tilt": "https://cdnjs.cloudflare.com/ajax/libs/tilt.js/1.2.1/tilt.jquery.min.js",
    "nouislider": "https://cdnjs.cloudflare.com/ajax/libs/noUiSlider/11.1.0/nouislider.min.js",
    "lightgallery": "https://cdnjs.cloudflare.com/ajax/libs/lightgallery/1.6.9/js/lightgallery-all.min.js",
    "masonry": "https://cdnjs.cloudflare.com/ajax/libs/masonry/4.2.1/masonry.pkgd.min.js",
    "cookieconsent": "https://packages.newlogic.cz/newlogic-cookieconsent/1.0.0/js/newlogic-cookieconsent.js"
};