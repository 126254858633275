(function($) {
    if (!html.hasClass("ie")) {
        $.fn.validation = function () {
            $(this).removeClass("mod--invalid mod--valid");
            if ($(this).find("input, textarea").val() !== "" && $(this).find("input, textarea").length) {
                if ($(this).find("input, textarea").is(":valid")) {
                    $(this).addClass("mod--valid");
                } else {
                    $(this).addClass("mod--invalid");
                }
            }
        };

        doc.on("change", ".part_ui_input", function () {
            if (!$(this).filter("[data-no-validation]").length) {
                $(this).validation();
            }
        });
        $(".part_ui_input").each(function () {
            if (!$(this).filter("[data-no-validation]").length && !$(this).hasClass("mod--invalid")) {
                $(this).validation();
            }
        });
    }
    $(".part_ui_input").each(function () {
        let $input = $(this).find("input, textarea");
        $input.filter("[required]").attr("placeholder",$input.attr("placeholder")+"*");
    });
})(jQuery);