(function ($) {
    let $fn = $(".comp_dealers_new");

    if ($fn.length) {
        let $map_el = $fn.find("[data-map]");
        let $initialized = false

        $.getScript(cdnjs.google_map).done(function () {
            let $markers = $map_el.data("markers"),
                $markers_temp = [],
                $map_options = {
                    zoom: 7.5,
                    mapTypeControl: false,
                    disableDefaultUI: true,
                    gestureHandling: 'cooperative',
                    center: {
                        lat: parseFloat("49.8037633"), lng: parseFloat("15.4749126")
                    },
                    styles: [
                        {
                            "stylers": [
                                {
                                    "lightness": -5
                                }
                            ]
                        },
                        {
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#212121"
                                }
                            ]
                        },
                        {
                            "elementType": "labels",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "elementType": "labels.icon",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#757575"
                                }
                            ]
                        },
                        {
                            "elementType": "labels.text.stroke",
                            "stylers": [
                                {
                                    "color": "#212121"
                                }
                            ]
                        },
                        {
                            "featureType": "administrative",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#757575"
                                }
                            ]
                        },
                        {
                            "featureType": "administrative.country",
                            "elementType": "geometry.stroke",
                            "stylers": [
                                {
                                    "color": "#c0c0c0"
                                },
                                {
                                    "weight": 1
                                }
                            ]
                        },
                        {
                            "featureType": "administrative.country",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#9e9e9e"
                                }
                            ]
                        },
                        {
                            "featureType": "administrative.land_parcel",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "administrative.land_parcel",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "color": "#2f2e31"
                                },
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "administrative.locality",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#bdbdbd"
                                }
                            ]
                        },
                        {
                            "featureType": "administrative.neighborhood",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "administrative.province",
                            "elementType": "geometry.stroke",
                            "stylers": [
                                {
                                    "color": "#77747c"
                                }
                            ]
                        },
                        {
                            "featureType": "landscape.man_made",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "landscape.natural",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "color": "#2f2e31"
                                }
                            ]
                        },
                        {
                            "featureType": "landscape.natural.landcover",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "landscape.natural.landcover",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "color": "#2f2e31"
                                },
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "landscape.natural.terrain",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "poi",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#757575"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.attraction",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.business",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.government",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#181818"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#616161"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "labels.text.stroke",
                            "stylers": [
                                {
                                    "color": "#1b1b1b"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.place_of_worship",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "road",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "road",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "color": "#2c2c2c"
                                }
                            ]
                        },
                        {
                            "featureType": "road",
                            "elementType": "labels.icon",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "road",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#8a8a8a"
                                }
                            ]
                        },
                        {
                            "featureType": "road.arterial",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#373737"
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#3c3c3c"
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway.controlled_access",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#4e4e4e"
                                }
                            ]
                        },
                        {
                            "featureType": "road.local",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#616161"
                                }
                            ]
                        },
                        {
                            "featureType": "transit",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "transit",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#757575"
                                }
                            ]
                        },
                        {
                            "featureType": "water",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "water",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#000000"
                                }
                            ]
                        },
                        {
                            "featureType": "water",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "color": "#706c75"
                                }
                            ]
                        },
                        {
                            "featureType": "water",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#3d3d3d"
                                }
                            ]
                        }
                    ]
                };
            let $map = new google.maps.Map($map_el[0], $map_options);

            let $infoWindow = new google.maps.InfoWindow({
                content: "",
                // disableAutoPan: true
            });

            let $bounds = new google.maps.LatLngBounds();

            for (let i = 0; i < $markers.length; i++) {
                let $marker = $markers[i],
                    $coords = $marker.coords.split(/[\s,]+/),
                    $position = new google.maps.LatLng($coords[0], $coords[1]),
                    $image = {
                        path: "M32 2a20 20 0 0 0-20 20c0 18 20 40 20 40s20-22 20-40A20 20 0 0 0 32 2zm0 28a8 8 0 1 1 8-8 8 8 0 0 1-8 8z",
                        fillColor: "#ed6a0f",
                        fillOpacity: 1,
                        strokeWeight: 0,
                        scale: 0.75,
                        anchor: new google.maps.Point(32, 64)
                    },
                    $icon = new google.maps.Marker({
                        position: $position,
                        map: $map,
                        icon: $image,
                        title: $marker.name,
                        id: $marker.id,
                        iw: {
                            region: $marker.region,
                            address: $marker.address,
                            url: $marker.url,
                            person: {
                                name: $marker.contact.name,
                                tel: $marker.contact.tel,
                                email: $marker.contact.email
                            }
                        }
                    });

                $markers_temp.push($icon);
                $bounds.extend($position);

                $map.fitBounds($bounds);

                google.maps.event.addListener($icon, 'click', showInfo($icon));

                google.maps.event.addListener($infoWindow, 'domready', function() {
                    $('.part_ui_dealer').closest('.gm-style-iw').parent().addClass('gm-dealers-iw');
                });
            }

            google.maps.event.trigger($markers_temp[0], 'click');

            setTimeout(function () {
                $initialized = true
            }, 150)

            function showInfo(marker) {
                return function () {
                    let url = "";
                    if (marker.iw.url) {
                        url = `<div class="elm_text"><a href="${marker.iw.url}" class="part_ui_link" target="_blank"><span>${marker.iw.url}</span></a></div>`;
                    }
                    let $content = `<div class="part_ui_dealer">
<strong class="elm_title mod--big">${marker.title}</strong>
<div class="elm_text">${marker.iw.region}</div>
<div class="elm_text">${marker.iw.address}</div>
${url}
<strong class="elm_title mod--small">Kontaktní osoba</strong>
<div class="elm_text">${marker.iw.person.name}</div>
<div class="elm_text">Tel.: <a href="tel:${marker.iw.person.tel.replace(/\s/g,'')}" class="part_ui_link"><span>${marker.iw.person.tel}</span></a></div>
<div class="elm_text">E-mail: <a href="mailto:${marker.iw.person.email}" class="part_ui_link"><span>${marker.iw.person.email}</span></a></div>
</div>`;
                    // $map.panTo(marker.position);
                    $infoWindow.setContent($content);
                    $infoWindow.open($map,marker);
                    $fn.find("[data-dealer-mobile]").html($content);
                    if(win.width() <= 960 && $initialized === true) {
                        $fn.find("[data-dealer-mobile]").anchor_anim(500);
                    }
                    $fn.find(".part_form_dealers_new").find("[name=\"branch\"]").val(marker.id);
                    $fn.find(".part_form_dealers_new").find("[name=\"branch\"]").find("[selected]").removeAttr("selected",true);
                    $fn.find(".part_form_dealers_new").find("[name=\"branch\"]").find("[value=\"" + marker.id + "\"]").attr("selected",true);
                }
            }
        });
    }
})(jQuery);
