(function ($) {
    let $fn = $(".comp_visual_header");

    if ($fn.length) {
        let $header = $fn.find(".wrp_scroll_header"),
            $layout = $("#layout_header");

        if($header.length) {

            $.getScript(cdnjs.headroom).done(()=> {
                setTimeout(function () {
                    let scrollheadroom = new Headroom($header[0], {
                        offset: $header.position().top + $header.outerHeight(),
                        tolerance : {
                            up : 0,
                            down : 0
                        },
                        classes: {
                            "initial" : "headroom",
                            "pinned": "headroom--pinned",
                            "unpinned": "headroom--unpinned",
                            "top" : "headroom--top",
                            "notTop" : "headroom--not-top"
                        },
                        onTop : function() {
                            $layout.removeClass("mod--stop");
                        },
                        onNotTop : function() {
                            $layout.addClass("mod--scroll mod--stop");
                        },
                    });
                    scrollheadroom.init();
                },100);
            });

            $header.on("click","[data-scroll]",function () {
                if($header.hasClass("headroom--top")){
                    $fn.next("section").anchor_anim(500);
                }
                else {
                    $("#layout_main").anchor_anim(500);
                }
            });

            let $nav_items = $header.find("nav").find("a"),
                $nav_select = $header.find("[data-nav-select]");

            $nav_select.on("change",function () {
                let $link = $nav_items.eq($(this).prop("selectedIndex"))[0];
                $link.click();
            })
        }

    }
})(jQuery);