$.fn.init_slider = function () {
    let $slider = $(this),
        _autoplay = $slider.data("slider"),
        _slides = $slider.data("min-slides");

    cssLoaded(()=>{
        if($slider.children().length > _slides) {
            $slider.flickity({
                imagesLoaded: false,
                percentPosition: false,
                setGallerySize: true,
                pageDots: false,
                wrapAround: true,
                contain: true,
                autoPlay: _autoplay,
                prevNextButtons: true,
                pauseAutoPlayOnHover: true,
                cellAlign: "left"
            })
        }
    });
};