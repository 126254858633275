(function ($) {
    const $fn = $(".comp_product_detail_features");

    if ($fn.length) {
        $fn.on("click","[data-more]",function (e) {
            e.preventDefault();
            let $el = $(this),
                $detail = $el.closest(".wrp_comp_item").find(".elm_item_detail");

            $el.toggleClass("mod--active");
            $detail.stop().slideToggle(500,function () {
                $detail.toggleClass("mod--active");
            });
        })
    }
})(jQuery);