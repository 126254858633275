(function ($) {
    let $fn = $(".comp_contact");

    if ($fn.length) {
        let $map = $fn.find("[data-map]");

        if($map.length) {
            $.getScript(cdnjs.google_map).done(function () {
                let $address = $map.data("address");
                $map.nl_google_map("init");
                $map.nl_google_map("markers_add", function($marker, $markers) {
                    let $infoWindow = new google.maps.InfoWindow({
                        content: "",
                        maxWidth: 266
                    });
                    function showInfo($marker) {
                        return function () {
                            let $content = "<div class='part_ui_info'><div class='elm_row'><strong class='elm_title'><span>"+$marker.title+"</span></strong><div class='elm_info'><span>"+$address+"</span></div></div>";
                            $infoWindow.setContent($content);
                            $infoWindow.setOptions({pixelOffset: { height: 90, width: 120}});
                            $infoWindow.open($map,$marker);
                        }
                    }
                    google.maps.event.addListener($marker, 'click', showInfo($marker));
                    google.maps.event.addListener($infoWindow, 'domready', function() {
                        $('.part_ui_info').closest('.gm-style-iw').parent().addClass('gm-custom-iw');
                    });
                    google.maps.event.trigger($markers[0], 'click');
                });
            });
        }
    }
})(jQuery);