(function ($) {
    let $fn = $(".comp_product_lines");

    if ($fn.length) {
        let $active = $fn.find(".mod--active"),
            $container = $fn.find(".elm_wrapper");

        $container.animate({
            scrollLeft: $active.position().left
        });
    }
})(jQuery);