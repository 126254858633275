(function ($) {
    let $fn = $(".comp_slider_products");

    if ($fn.length) {
        let $slider_text = $fn.find("[data-slider-text]"),
            $slider_images = $fn.find("[data-slider-images]"),
            $lines = $fn.find("[data-line]");

        cssLoaded(function () {
            if($slider_text.children().length > 1){
                $slider_text.flickity({
                    percentPosition: false,
                    setGallerySize: true,
                    pageDots: false,
                    wrapAround: true,
                    contain: true,
                    autoPlay: false,
                    prevNextButtons: false,
                    pauseAutoPlayOnHover: true,
                    sync: $slider_images[0]
                });

                $slider_images.flickity({
                    contain: true,
                    setGallerySize: true,
                    prevNextButtons: true,
                    pageDots: false,
                    wrapAround: true,
                    imagesLoaded: true,
                });

                let flkty = $slider_images.data("flickity");

                $slider_images.on("select.flickity",function () {
                    let index = flkty.selectedIndex;
                    let index_prev = (index-1 >= 0) ? index-1 : flkty.slides.length-1;
                    let index_next = (index+1 <= flkty.slides.length-1) ? index+1 : 0;
                    let prev_name = $(flkty.slides[index_prev].cells[0].element).data("name");
                    let next_name = $(flkty.slides[index_next].cells[0].element).data("name");

                    $(flkty.nextButton.element).attr("data-label",next_name);
                    $(flkty.prevButton.element).attr("data-label",prev_name);

                    $lines.filter(".mod--active").hide().removeClass("mod--active");
                    $lines.eq(flkty.selectedIndex).show().addClass("mod--active");
                });
            }
        })
    }
})(jQuery);