(function ($) {
    let $fn = $(".comp_dealers");

    if ($fn.length) {
        let $map = $fn.find(".elm_map svg"),
            $regions = $map.find("[data-region]"),
            $nav_items = $map.find("[data-nav-item]"),
            $area_items = $fn.find(".elm_content [data-area-item]"),
            $options = $fn.find(".part_form select").find("option"),
            $nav_select = $fn.find("[data-nav-select]"),
            $nav_options = $nav_select.find("option");

        win.on("resize",function () {
            if(win.width() >= 960) {
                $nav_items.filter(".mod--active").first().trigger("click");
            }
        });

        $nav_items.on("click",function (e) {
            e.stopPropagation();
            $nav_select.val($(this).data("nav-item")).trigger("change");
        });
        $regions.on("click",function () {
            if(win.width() < 960) {
                $nav_select.val($(this).data("region")).trigger("change");
            }
        });

        $nav_select.on("change", function () {
            let $this = $(this),
                val = $this.val(),
                $option = $nav_options.eq($this[0].selectedIndex);

            $nav_items.filter(".mod--active").removeClass("mod--active");
            $regions.filter(".mod--active").removeClass("mod--active");
            $area_items.filter(".mod--active").hide().removeClass("mod--active");

            if($options.length) {
                $options.filter("[selected]").prop("selected",false);
                $options.filter("[value=\""+ val +"\"]").prop("selected",true);
            }

            if($option.is("[data-region]") && win.width() < 960) {
                let $region = $regions.filter("[data-region=\"" + val + "\"]"),
                    $branches = $region.children().filter("[data-nav-item]");

                $region.addClass("mod--active");

                $branches.each(function () {
                    let $map_item = $(this);
                    $map_item.addClass("mod--active");
                    $area_items.filter("[data-area-item=\"" + $map_item.data("nav-item") + "\"]").show().addClass("mod--active");
                });
            }
            else {
                let $map_item = $nav_items.filter("[data-nav-item=\""+ val +"\"]");

                $map_item.addClass("mod--active");
                $map_item.parents("[data-region]").addClass("mod--active");
                $area_items.filter("[data-area-item=\"" + $map_item.data("nav-item") + "\"]").show().addClass("mod--active");
            }
        })
    }
})(jQuery);