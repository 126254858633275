(function ($) {
    let $fn = $(".comp_events_map");

    if ($fn.length) {
        let $map = $fn.find("[data-map]");

        $.getScript(cdnjs.google_map).done(function () {
            $map.nl_google_map("init");
            $map.nl_google_map("markers_add", function($marker, $markers) {
                google.maps.event.addListener($marker, 'click', function () {
                    window.location.href = this.url;
                });
            });
        });
    }
})(jQuery);